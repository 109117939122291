import React, { useState, useEffect, useCallback } from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";

const beu = "0x4e4a2ad4f7b63819128545a27cabe6cf7db68366";
//const baby = "0xE6C6f56Ffb0D88C88945cF2E07cbFF79081acb71";
//https://gateway.pinata.cloud/ipfs/QmceD6WDhLN13kCtEsoqiqTCrXUt73191d9A1EkbgPG5PY/
const chain = "polygon";
const imgURL = "https://gallery.thebeu.io/images/";

const App = ({empty}) => {

    const { isInitialized } = useMoralis();
    const Web3Api = useMoralisWeb3Api();

    const [tokenArray, setTokenArray] = useState([{}]);
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage] = useState(12)
    const [pageLimit, setPageLimit] = useState("")
    const [load, setLoad] = useState(true)
    
    const fetchAllTokenIds = useCallback(async () => { 
        const NFTs = await Web3Api.token.getAllTokenIds({
            address: beu,
            chain: chain,
        });
        const sortedData = await [...NFTs.result].sort((a,b) => {
            return a.token_id > b.token_id ? 1 : -1
        });
        await setTokenArray(sortedData);
        await setPageLimit(Math.ceil(NFTs.total/12));
        await setLoad(false)
;    }, [Web3Api]);

    useEffect(() => {if (isInitialized && load === true) {fetchAllTokenIds()}}, [load, isInitialized, fetchAllTokenIds]);
    useEffect(() => {if (tokenArray.length === 0) {setLoad(true)}}, [setLoad, tokenArray]);


    const indexLast = currentPage * perPage;
    const indexFirst = indexLast - perPage;
    const current = tokenArray.slice(indexFirst, indexLast);

    const page = (page) => setCurrentPage(page);

    return (
        <div className="elementor-widget-container">
            <div id="gallery-container">
                {tokenArray.length > 0 ? (current.map(({ token_id }, key) => (
                    <div key={key} className="gallery-block" style={{display: "block"}}>
                        <img alt={"BeU #" + token_id} src={imgURL + token_id + ".png"} width="274" height="274" />
                        <h6>BEU</h6>
                        <p>No. {token_id}</p>
                    </div>
                ))) : ""}
            </div>
            <div id="page_navigation">
                <a href={empty} onClick={() => {if(currentPage === 1) {alert("This is the first page!")} else {page(currentPage - 1)}}} className="previous_link">Prev</a>
                <a href={empty} className="page_link active_page">{currentPage}/{pageLimit}</a>
                <a href={empty} onClick={() => {if(currentPage === pageLimit) {alert("This is the last page!")} else {page(currentPage + 1)}}} className="next_link">Next</a>
            </div>
        </div>
    );
};

export default App;
